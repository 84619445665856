import React, { useState } from 'react';
import axios from 'axios';
import logo from './ux_logo.png';
import lightbulb from './light.svg';
import book from './book.svg';
import coffee from './coffee.svg';

const Sidebar = ({ onCategoryChange, selectedCategory, isOpen, closeSidebar, isSmallScreen }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleCategoryClick = (category) => {
    onCategoryChange(category);
    closeSidebar(); 
  };

  const handleSubscribe = async () => {
    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    const API_KEY = 'your-convertkit-api-key'; // Replace with your ConvertKit API Key
    const FORM_ID = 'your-convertkit-form-id'; // Replace with your ConvertKit Form ID

    try {
      const response = await axios.post(
        `https://api.convertkit.com/v3/forms/${FORM_ID}/subscribe`,
        {
          api_key: API_KEY,
          email: email,
        }
      );
      if (response.data.subscription) {
        setMessage('Thank you for subscribing!');
        setEmail(''); // Clear the email input after a successful subscription
      } else {
        setMessage('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'active' : ''}`}>
      {isSmallScreen && (
        <div className="header-open">
          <div className="logo-container">
            <img src={logo} className="ux-logo" alt="UX Logo" />
          </div>
          <div className="icon-container">
            <button className="toggle-sidebar-btn close-button" onClick={closeSidebar}>
              &times;
            </button>
          </div>
        </div>
      )}
      <div className="sidebar-top">
        <img className="logo" src={logo} alt="UX Breakdowns Logo" />
        <h2>Principles, ideas, and stories to help you build and grow better products.</h2>
        <nav>
          <ul>
            <li>
              <a
                href="#!"
                className={`svg-icon ${selectedCategory === 'Ideas & Principles' ? 'active-text active-icon' : ''}`}
                onClick={() => handleCategoryClick('Ideas & Principles')}
              >
                <img src={lightbulb} className={`svg-icon ${selectedCategory === 'Ideas & Principles' ? 'active-icon' : ''}`} alt="Ideas & Principles" />
                Ideas & Principles
              </a>
            </li>
            <li>
              <a
                href="#!"
                className={`svg-icon ${selectedCategory === 'Stories' ? 'active-text active-icon' : ''}`}
                onClick={() => handleCategoryClick('Stories')}
              >
                <img src={book} className={`svg-icon ${selectedCategory === 'Stories' ? 'active-icon' : ''}`} alt="Stories" />
                Stories
              </a>
            </li>
            <li>
              <a
                href="#!"
                className={`svg-icon ${selectedCategory === 'Roasts' ? 'active-text active-icon' : ''}`}
                onClick={() => handleCategoryClick('Roasts')}
              >
                <img src={coffee} className={`svg-icon ${selectedCategory === 'Roasts' ? 'active-icon' : ''}`} alt="Roasts" />
                Roasts
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="sidebar-bottom">
        <input
          type="email"
          placeholder="youremail@gmail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="subscribe-button" onClick={handleSubscribe}>
          Subscribe to our newsletter
        </button>
        <p>{message}</p>
        <button
          className="design-services-button"
          onClick={() => window.location.href = 'https://laylinedesign.com/'}
        >
          Need design services?
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
