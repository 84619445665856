import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import classNames from 'classnames';
import Sidebar from './Sidebar';
import Modal from './Modal';
import logo from './ux_logo.png';
import './Sidebar.css';
import './App.css';
import ReactMarkdown from 'react-markdown';

function PostContent({ content }) {
  return (
    <div className="post-content">
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
}

const client = createClient({
  space: 'f4pjv0zb14pf', // Replace with your space ID
  accessToken: 'bTFU1rWhun24KuAzDghMuuiWuCdSQDT2rSBS7qTiq44' // Replace with your access token
});

const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

function App() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Ideas & Principles');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);
  const [titleLimit, setTitleLimit] = useState(80); // Default character limit for title
  const [summaryLimit, setSummaryLimit] = useState(180); // Default character limit for summary

  useEffect(() => {
    client.getEntries({ content_type: 'blogPost' })
      .then((response) => {
        setPosts(response.items);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth <= 800);

      if (screenWidth <= 400) {
        setTitleLimit(15);
        setSummaryLimit(80);
      } else if (screenWidth <= 600) {
        setTitleLimit(25);
        setSummaryLimit(80);
      } else if (screenWidth > 600 && screenWidth <= 700) {
        setTitleLimit(40);
        setSummaryLimit(100);
      } else if (screenWidth > 700 && screenWidth <= 800) {
        setTitleLimit(50);
        setSummaryLimit(120);
      } else if (screenWidth > 800 && screenWidth <= 900) {
        setTitleLimit(30);
        setSummaryLimit(65);
      } else if (screenWidth > 900 && screenWidth <= 1000) {
        setTitleLimit(45);
        setSummaryLimit(90);
      } else if (screenWidth > 1000 && screenWidth <= 1100) {
        setTitleLimit(55);
        setSummaryLimit(120);
      } else if (screenWidth > 1100 && screenWidth <= 1200) {
        setTitleLimit(65);
        setSummaryLimit(130);
      } else if (screenWidth > 1400 && screenWidth <= 1600) {
        setTitleLimit(35);
        setSummaryLimit(80);
      } else {
        setTitleLimit(50);
        setSummaryLimit(100);
      }

      // Automatically close the sidebar if the screen is resized to a larger width
      if (screenWidth > 800 && isSidebarOpen) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially to set the limits based on the initial screen size

    return () => window.removeEventListener('resize', handleResize);
  }, [isSidebarOpen]);

  const handlePostClick = post => setSelectedPost(post);
  const handleClosePost = () => {
    setSelectedPost(null);
  };
  const handleCloseModal = () => setSelectedPost(null);
  const handleCategoryChange = (category) => { 
    setSelectedCategory(category);
    setSelectedPost(null);
  }
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const closeSidebar = () => setIsSidebarOpen(false);

  const filteredPosts = selectedCategory === 'All'
    ? posts
    : posts.filter(post => post.fields.category === selectedCategory);

    return (
      <div className={classNames('app', { 'sidebar-open': isSidebarOpen })}>
        {(isSmallScreen && !isSidebarOpen) && (
          <div className="header">
          <div className="logo-container">
            <img src={logo} className="ux-logo" alt="UX Logo" />
          </div>
          <div className="icon-container">
            <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
              <div className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
          </div>
        )}
        <Sidebar
          onCategoryChange={handleCategoryChange}
          selectedCategory={selectedCategory}
          isOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
          isSmallScreen={isSmallScreen}
        />
        <div className={`content ${selectedPost ? 'allow-scroll' : 'no-scroll'}`} onClick={closeSidebar}>
          {selectedPost ? (
            // Render Post Details
            <div className='individual-post'>
              <button className="individual-post-return" onClick={handleClosePost}>Return</button>
              <h1 className='individual-post-title'>{selectedPost.fields.title}</h1>
              <img className="individual-post-image" src={selectedPost.fields.mainMedia.fields.file.url} alt={selectedPost.fields.title} />
              <PostContent content={selectedPost.fields.body} />
            </div>
          ) : (
            // Render List of Posts
            <>
              <h1 className="filter-title">{selectedCategory}</h1>
              <div className={`blog-posts ${selectedCategory.toLowerCase().replace(/\s+/g, '-').replace(/&/g, 'and')}`}>
                {filteredPosts.length === 0 ? (
                  <p>No posts available in this category.</p>
                ) : (
                  filteredPosts.map(post => (
                    <div 
                      key={post.sys.id}           
                      className={`blog-post ${selectedCategory === 'Ideas & Principles' ? 'blog-post-ideas' : ''}`} 
                      onClick={() => handlePostClick(post)}
                    >
                      <img src={post.fields.mainMedia.fields.file.url} alt={post.fields.title} className={`blog-post-image ${selectedCategory === 'Ideas & Principles' ? 'blog-post-ideas-image' : ''}`}/>
                      <div className="blog-post-text">
                        <p className={`blog-title ${selectedCategory === 'Ideas & Principles' ? 'blog-post-ideas-title' : ''}`}>
                          {truncateText(post.fields.title, titleLimit)}
                        </p>
                        {selectedCategory === 'Stories' && (
                          <p className="blog-summary">
                            {truncateText(post.fields.summary, summaryLimit)}
                          </p>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );     
}

export default App;
